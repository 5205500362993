export const patientJames = require("./1.1/patient-james-profile-img.jpg");
export const patientDonna = require("./1.1/patient-donna-profile-img.jpg");
export const patientDavid = require("./1.1/patient-david-profile-img.jpg");
export const patientMichael = require("./1.1/patient-michael-profile-img.png");
export const patientMaria = require("./1.1/patient-maria-profile-img.png");
export const patientRobert = require("./1.1/patient-robert-profile-img.png");

export const patientJames_mob = require("./1.1/mobile/patient3_mobile.jpg");
export const patientDonna_mob = require("./1.1/mobile/patient2_mobile.jpg");
export const patientDavid_mob = require("./1.1/mobile/patient1_mobile.jpg");
export const patientMichael_mob = require("./1.1/mobile/anthony_mobile.jpg");
export const patientMaria_mob = require("./1.1/patient-maria-profile-img.png");
export const patientRobert_mob = require("./1.1/patient-robert-profile-img.png");