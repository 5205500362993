import React from "react";
import './video.styles.css';

const VideoHeaderMyth = ({myth, fact}) => (
    <div className="myth-header-container">
        <p><span className="myth-label">Myth:</span> <span className="myth-desc" dangerouslySetInnerHTML={{__html: myth}} /></p>
        <p><span className="fact-label">Fact:</span> <span className="myth-desc" dangerouslySetInnerHTML={{__html: fact}} /></p>
    </div>
)

export default VideoHeaderMyth;