import React from "react"
import {
  patientMaria,
  patientMaria_mob,
  patientRobert,
  patientRobert_mob
} from "../../images/imgExporter"

import Collapsible from '../collapsible/Collapsible'
import { Link } from "gatsby"

import VideoHeaderMyth from '../Video/VideoHeaderMyth'
import Video from '../Video/Video'

/* styles */
import "./styles.css"

export default (props) => {
  const collapsiblewidth = props.isMobile ? '100%' : '505px';

  return (
    <main className='main'>
      <section id="section-1">
        <div id="LDL_lowering_video" className="mf-video-section">
          <VideoHeaderMyth 
          myth="Reduction in LDL-C eliminates Persistent CV Risk (P-CVR)." 
          fact="Dr. Payal Kohli, an expert in preventive cardiology, says P-CVR remains despite LDL-C control.<sup class='sup-8'>2-4</sup>" />
          <Video  video_id="486972087" video_id_2="489545925" video_id_3="257211618" headline="Watch Dr. Kohli review the LDL-C facts and myths below" />
        </div>
      </section>
      <hr />
      <section id="section-2" name="patient1" className='endpoints-patient'>
        <p className="cv-body-copy">
          Approximately every 37-40 seconds, someone will experience an MI or Stroke, or die from CVD in the United States. And the prevalence of heart disease in both men and women is only expected to grow.<sup>5,6</sup> But, who is at risk?
        </p>
          <h3 class="video-headline m-b-30">Meet the patient types who may benefit from VASCEPA</h3>
          <div className="display-flex">
            <div className="patient-img-container">
              <img className="patient-image" src={props.isMobile ? patientMaria_mob : patientMaria} alt="Maria's photo"/>
              <p className="cv-footnote mobile-m-y-5">Not an actual VASCEPA patient.</p>
            </div>

            <div  className="patient-info-container">
              <p className="patient-subheadline">Maria, 57</p>
              <ul className="content-list p-b-10">
                <li className="primary-list-item margin-0 pat-list">
                  Established CVD and history of MI
                </li>
                <li className="primary-list-item margin-0 pat-list">
                  On statin therapy
                </li>
                <li className="primary-list-item pat-list">
                  TG: 185 mg/dL
                </li>
              </ul>

              <div style={{width:collapsiblewidth}} className="collapsible-and-cta">
                <Collapsible title={"Additional info on Maria"} w={"100%"}>
                  <p className="patient-copy"><span className="vascepa-blue">Reason for visit:</span> Had a prior MI and is coming in for her <span class="nowrap">3-month,</span> post-MI visit</p>
                  <div className="blue-backround-container">
                    <p className="vascepa-blue m-0">Clinical CVD diagnosis: </p>
                    <ul className="m-0 fw-100">
                      <li className="">MI with stent placement 3 months ago
                      </li>
                      <li className="">Hypertension
                      </li>
                      <li className="">High cholesterol
                      </li>
                    </ul>
                  </div>

                  <div className="ko-container">
                    <p className="vascepa-blue m-0">Family history:</p>
                    <ul className="m-0 fw-100">
                        <li className="pw-100">CVD, father died from an MI at 63 yrs. old
                        </li>
                    </ul>
                  </div>

                  <div className="blue-backround-container">
                    <p className="vascepa-blue m-0">Medications:</p>
                    <ul className="m-0 fw-100">
                      <li className="">Atorvastatin 80 mg QD
                      </li>
                      <li className="">Valsartan 160 mg QD
                      </li>
                      <li className="">Clopidogrel 75 mg QD
                      </li>
                      <li className="">Metoprolol ER 100 mg QD
                      </li>
                      <li className="">ASA 325 mg QD
                      </li>
                    </ul>
                  </div>

                  <div className="ko-container">
                    <p className="vascepa-blue m-0">Diagnostics: </p>
                    <ul className="m-0 fw-100">
                      <li className="">LDL-C: 68 mg/dL
                      </li>
                      <li className="">TG: 185 mg/dL
                      </li>
                      <li className="">HDL-C: 40 mg/dL
                      </li>
                      <li className="">BP: 132/80 mm Hg
                      </li>
                      <li className="">BMI: 34
                      </li>
                    </ul>
                  </div>

                 
                </Collapsible>

                <div className="cta-container cta-vas-pat">
                  <h3 className="cta-copy">VASCEPA can help patients like Maria</h3>
                  <Link className="cta-btn" to="/total-events-analysis/">See how</Link>
                </div>

              </div>

            </div> {/* Patient Info Container ends */}
          </div>
      </section>

      <hr/>

      <section id="section-3" name="patient2" className='endpoints-patient'>
        <div className="display-flex">
            <div className="patient-img-container">
              <img className="patient-image" src={props.isMobile ? patientRobert_mob : patientRobert} alt="Robert's photo"/>
              <div className="footnotes">
              <p className="cv-footnote">
                Not an actual VASCEPA patient.
              </p>
            </div>
            </div>

            <div className="patient-info-container">
              <p className="patient-subheadline">Robert, 65</p>
              <ul className="content-list p-b-20">
                <li className="primary-list-item margin-0 pat-list">
                  Established CVD and history of stroke
                </li>
                <li className="primary-list-item margin-0   pat-list">
                  On statin therapy
                </li>
                <li className="primary-list-item  pat-list">
                  TG: 260 mg/dL
                </li>
              </ul>
              <div style={{width:collapsiblewidth}} className="collapsible-and-cta">
                <Collapsible title={"Additional info on Robert"} w={"100%"}>
                  <p className="patient-copy"><span className="vascepa-blue">Reason for visit:</span> Had a prior stroke and is coming in for his routine office visit </p>
                  <div className="blue-backround-container">
                    <p className="vascepa-blue m-0">Clinical CVD diagnosis: </p>
                    <ul className="m-0 fw-100">
                      <li className="">
                      Stroke at 64 yrs. old
                      </li>
                      <li className="">
                      High cholesterol
                      </li>
                      <li className="">
                      PAD
                      </li>
                      <li className="">
                      Hypertension
                      </li>
                    </ul>
                  </div>

                  <div className="ko-container">
                    <p className="vascepa-blue m-0">Family history:</p>
                    <ul className="m-0 fw-100">
                        <li className="">
                        Extensive family history of CV disease
                        </li>
                    </ul>
                  </div>
                  <div className="blue-backround-container">
                    <p className="vascepa-blue m-0">Medications:</p>
                    <ul className="m-0 fw-100">
                      <li>
                        Rosuvastatin 20 mg QD
                      </li>
                      <li>
                        Clopidogrel 75 mg QD
                      </li>
                      <li>
                        ASA 325 mg QD
                      </li>
                      <li>
                        Valsartan 160 mg QD
                      </li>
                      <li>
                        Amlodipine 10 mg QD
                      </li>
                    </ul>
                  </div>

                  <div className="ko-container">
                    <p className="vascepa-blue m-0">Diagnostics: </p>
                    <ul className="m-0 fw-100">
                      <li>
                        LDL-C: 88 mg/dL
                      </li>
                      <li>
                        TG: 260 mg/dL
                      </li>
                      <li>
                        HDL-C: 40 mg/dL
                      </li>
                      <li>
                        BP: 132/80 mm Hg
                      </li>
                      <li>
                        BMI: 25
                      </li>
                    </ul>
                  </div>

                  

                </Collapsible>
                <div className="cta-container cta-vas-pat">
                  <h3 className="cta-copy">VASCEPA can help patients like Robert</h3>
                  <Link className="cta-btn" to="/outcomes-data/">See&nbsp;how</Link>
                </div>
              </div>

            </div> {/* Patient Info Container ends */}
          </div>
      </section>


    </main>
  )
}
