import Scrollspy from 'react-scrollspy'
import React from "react"

export default (props) => (
	<nav className={props.stickyNav ? 'stickyNav nav' : 'nav'}>
	  <Scrollspy  
	  items={ ['section-1', 'section-2', 'section-3', 'section-4'] } 
	  currentClassName="is-current" offset={0}>
	    <li ><a href="#section-1">Remaining Risk</a></li>
	    <li><a href="#section-2">Patient with Established CVD (MI)</a></li>
	    <li><a href="#section-3">Patient with Established CVD (Stroke)</a></li>
	  </Scrollspy>
	</nav>
)
