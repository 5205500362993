import React, {useEffect, useState} from 'react'
import Vimeo from '@vimeo/player'
import './video.styles.css'
import {Link} from 'gatsby'

const Video = ({video_id, video_id_2, video_id_3, URL, headline}) => {
    const [video, setVideo] = useState('');

    const video_width = "500"
    const video_height = "auto"
    
    const playSecondVideo = () => {
        setVideo(video_id_2)
        let iframe = document.querySelector('#video-iframe-'+ video_id)
        iframe.src = `https://player.vimeo.com/video/${video}`
        setupVideo()
    }
    // const playThirdVideo = () => {
    //     let iframe = document.querySelector('#video-iframe')
    //     iframe.src = `https://player.vimeo.com/video/${video_id_3}`
    //     setupVideo()
    // }
    const setupVideo = () => {
        let overlay = document.querySelector('.vimeo-overlay-'+ video_id)
        overlay.classList.remove('ended')
        let iframe = document.querySelector('#video-iframe-'+ video_id )
        let player = new Vimeo (iframe)
        player.on('ended', function(data) {
            overlay.classList.add('ended')
          });
        
    }
    useEffect(() => {
        
        setTimeout(function(){ 
            setVideo(video_id);
            setupVideo()
        
         }, 500);

       
      }, []);

    return (
        <div id="vimeo-overlay-container">
            <h3 className="video-headline" dangerouslySetInnerHTML={{__html: headline}}/>
            <div className="video-overlay-container">
                <div className="embed-container"> 
                <iframe 
                    id={"video-iframe-" + video_id }
                    src= {`https://player.vimeo.com/video/${video}`} 
                    className="vimeo-iframe"
                    width="800" height="450" 
                    frameBorder="0" 
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowfullscreen="true">
                </iframe>
                </div>
                <div className={"vimeo-overlay vimeo-overlay-"+ video_id}>
                    {/* <div className="second-video-container" onClick={() => playSecondVideo()}>
                        <img src="https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA23689_hires.jpg" />
                    </div>
                    <div className="url-container">
                        <a href={URL} target="_blank">Very Important Link</a>
                    </div> */}
                    <div className="video-overlay-block">
                    <span>Learn more about the Important Safety Information</span>
                    <button onClick={() => playSecondVideo()} >Watch now</button> 
                    </div>
                    <div className="video-overlay-block">
                    <span>See the groundbreaking results from the VASCEPA CV Outcomes Trial (REDUCE-IT<sup>&reg;</sup>)</span>
                    <Link to="/outcomes-data/">View now</Link> 
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Video